import React from 'react'
import { EmptyHeartIcon, EmptyStarIcon, LibraryIcon, MoneyIcon } from '@hermes/web-components'
import useTranslateMessage from '@hooks/useTranslateMessage'

import { SubHeaderContainer, ItemContainer, ItemIcon, ItemTypography, SubHeaderWrapper } from './SubHeader.styles'

const SubHeader = () => {
  const { t } = useTranslateMessage()

  const items = [
    {
      icon: <MoneyIcon width="20px" height="20px" />,
      title: t({ id: 'subHeader.alwaysLowPrices' })
    },
    {
      icon: <LibraryIcon width="20px" height="20px" />,
      title: t({ id: 'subHeader.largestSelection' })
    },
    {
      icon: <EmptyStarIcon width="20px" height="17.5px" />,
      title: t({ id: 'subHeader.googleRating' })
    },
    {
      icon: <EmptyHeartIcon width="20px" height="20px" />,
      title: t({ id: 'subHeader.easyBuyAndSell' })
    }
  ]

  return (
    <SubHeaderContainer>
      <SubHeaderWrapper maxWidth="xl">
        {items.map((item, index) => (
          <ItemContainer key={index}>
            <ItemIcon>{item.icon}</ItemIcon>
            <ItemTypography>{item.title}</ItemTypography>
          </ItemContainer>
        ))}
      </SubHeaderWrapper>
    </SubHeaderContainer>
  )
}

export default SubHeader
