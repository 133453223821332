import { styledComponent, Box, Typography, Container } from '@hermes/web-components'

export const SubHeaderContainer = styledComponent(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.neutral[200]}`
}))

export const SubHeaderWrapper = styledComponent(Container)(({ theme }) => ({
  padding: '24px 16px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  gap: '37px',
  [theme.breakpoints.down('sm')]: {
    padding: '14px 0',
    flexDirection: 'column',
    gap: '6px'
  }
}))

export const ItemContainer = styledComponent(Box)({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '10px'
})

export const ItemIcon = styledComponent(Box)({
  display: 'flex',
  alignItems: 'center'
})

export const ItemTypography = styledComponent(Typography)(({ theme }) => ({
  lineHeight: '24px',
  fontSize: '16px',
  fontWeight: 500,
  [theme.breakpoints.down('xs')]: {
    fontSize: '14px'
  }
}))
