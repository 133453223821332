'use client'

import { RootLayout } from '@components/RootLayout'
import { Box, Stack } from '@hermes/web-components'
import SubHeader from '@components/SubHeader'
import React from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import 'swiper/css'
import 'swiper/css/pagination'

const NormalLayout = ({ children }: { children: React.ReactNode }) => (
  <>
    <RootLayout>
      <SubHeader />
      <Stack width="100%" sx={{ justifyContent: 'center', alignItems: 'center', padding: '0 16px' }} spacing={6}>
        <Box maxWidth="xl" width="100%">
          {children}
        </Box>
      </Stack>
    </RootLayout>
  </>
)
export default NormalLayout
